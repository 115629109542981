import {useEffect, useRef} from "react";
import {Ana_Sayfa_Ko_Mobile, Nonpaid_User} from "../../../assets/img"
import {useLocalization} from "../../../hooks/useLocalization"
import {ModalOneButton, ModalOverlay, ModalYoutube} from "../../../components";
import {Link, useNavigate} from "react-router-dom"
import {url} from '../../../routes/utility';
import useAnalytics from "../../../hooks/useAnalytics";
import {PlanYourLessonService} from "../../../services/SpeakingLesson/SpeakingLessonService";
import useAuth from "../../../hooks/useAuth";
import {closeModal, openModal} from "../../../utils";
import {EventLogs} from "../../../components/Constants";

const DailyLessonNonUser = () => {

    const strings = useLocalization();
    const { member } = useAuth();
    const analytics = useAnalytics();
    const navigate = useNavigate();

    const videoUrl = "https://www.youtube.com/embed/V-5QdzhEYZc";

    const modalRef = useRef();
    const modalMessageRef = useRef();
    const modalOverlayRef = useRef();

    const planYourLesson = async () => {
        await PlanYourLessonService(member.MemberId);
        openModal(modalOverlayRef, modalMessageRef);
        analytics.useAnalytics('Konusma_Dersi_Banner_Click');
    }

    useEffect(() => {
        analytics.useAnalytics(EventLogs.SPEAKING_LESSON_CANDIDATE);
    }, []);

    return (
        <>
            <div className="pt-6 mb-5">
                <p className="font-bold text-xl">{strings.speaking_lesson.speaking_lesson_non_user.hello}</p>
                <p className="text-lg">{strings.speaking_lesson.speaking_lesson_non_user.hello_title}</p>
                <p className="font-bold text-xl mt-[18px]">{strings.speaking_lesson.speaking_lesson_non_user.sample_lesson}</p>
                <img className="rounded-[10px] w-full max-w-[438px] mt-[10px] mx-auto cursor-pointer" src={Nonpaid_User} alt=""
                    onClick={() => {
                        openModal(modalOverlayRef, modalRef);
                        analytics.useAnalytics('Konusma_Dersi_Video_View');
                    }} />
            </div>

            <div className="btn-outer-div mt-5 mb-10 sm:mb-14">
                <Link to={url('market')} onClick={() =>
                    analytics.useAnalytics('Konusma_Dersi_Egitim_Paket_Incele_Click')
                } className="button primary-button">
                    {strings.speaking_lesson.speaking_lesson_non_user.view_packages}
                </Link>
            </div>

            <div className="dashboard-header relative">
                <div className="absolute bottom-0 left-0">
                    <img className="w-[150px] md:w-[176px] rounded-bl-[10px]" src={Ana_Sayfa_Ko_Mobile} alt="" />
                </div>
                <div className="w-[calc(100%-115px)] md:w-[calc(100%-160px)] flex flex-col h-full justify-between pr-3">
                    <p>{strings.speaking_lesson.speaking_lesson_non_user.plan_course_title}</p>
                    <button onClick={planYourLesson}
                        className="button ml-auto sm:mx-auto text-sm bg-white text-base-text h-[30px] flex items-center justify-center w-28 sm:w-40 hover:text-secondary focus:text-secondary">
                        {strings.speaking_lesson.speaking_lesson_non_user.plan_course}
                    </button>
                </div>
            </div>

            {/* --- Modal --- */}

            <ModalOverlay ref={modalOverlayRef} />
            <ModalYoutube
                ref={modalRef}
                videoUrl={videoUrl}
                overlayRef={modalOverlayRef}
            />
            <ModalOneButton
                ref={modalMessageRef}
                title={strings.speaking_lesson.speaking_lesson_non_user.modal_title}
                message={strings.speaking_lesson.speaking_lesson_non_user.modal_message}
                overlayRef={modalOverlayRef}
                buttonText={strings.speaking_lesson.speaking_lesson_non_user.okay_button}
                buttonClick={() => { closeModal(modalOverlayRef, modalMessageRef); navigate(url("teachers")) }}
            />
        </>
    )
}

export default DailyLessonNonUser