import {useLocalization} from "../../../hooks/useLocalization";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {EducationProgramDetailService} from "../../../services/Market/MarketEducationPackageService";
import {Accordion, ActionIcons, LevelTab, Loading} from "../../../components";
import classNames from "classnames";
import {getActionIconByActionStatus, getLanguageIdByLanguageKey, parseMobileKeyToJson} from "../../../utils";
import {BookModal} from "../../../components/Modals";
import {url} from "../../../routes/utility";
import {Idea} from "../../../assets/svg";
import {useSelector} from "react-redux";
import useAnalytics from "../../../hooks/useAnalytics";
import useAuth from "../../../hooks/useAuth";
import {EventLogs, MemberStatus} from "../../../components/Constants";

const Detail = () => {

    const strings = useLocalization();
    const { programId } = useParams();
    const { member } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const language = useSelector(state => state.localizationStore.language);
    const analytics = useAnalytics();

    const IS_MOBILE = location.pathname.includes("/mobile_market_education_packages_detail");
    // Landing page'lerde bu sayfayı embed edip kullanıldığında bu değer true oluyor.
    const IS_EMBEDDED_URL = !location.pathname.includes("market");
    const memberInfo = sessionStorage.getItem("m") ? (parseMobileKeyToJson(sessionStorage.getItem("m"))) : member;

    const modalRef = useRef();

    const [loading, setLoading] = useState(true);

    // Data ile alakalı state'ler
    const [data, setData] = useState({});
    const [visibleData, setVisibleData] = useState({});
    const [achievements, setAchievements] = useState({});

    // Tablar ile alakalı state'ler
    const [selectedTab, setSelectedTab] = useState(null);

    // Kitap modalı ile alakalı stateler
    const [showModal, setShowModal] = useState(false);
    const [selectedBookUrl, setSelectedBookUrl] = useState("");

    const openModal = async (bookContent, bookId) => {
        setSelectedBookUrl(selectedBookUrl => bookContent.bookUrl);
        setShowModal(showModal => true);
        modalRef.current.classList.remove("hidden");

        !IS_EMBEDDED_URL && analytics.useAnalytics(EventLogs.EDUCATION_PROGRAM_MARKET_BOOK_CLICKED);
    }

    const closeModal = () => {
        setShowModal(showModal => false);
        setSelectedBookUrl(selectedBookUrl => "");
        modalRef.current.classList.add("hidden");
    };

    const handleNavigateButton = () => {
        IS_EMBEDDED_URL 
            ? navigate(url("packagecatalog"))
            : IS_MOBILE
                ? navigate(url("mobile_market_package_catalog"))
                : navigate(url("market.packagecatalog"))
    }

    useEffect(() => {

        setLoading(loading => true);

        EducationProgramDetailService(programId, getLanguageIdByLanguageKey(language), IS_EMBEDDED_URL ? MemberStatus.Candidate : memberInfo?.Status)
            .then(result => {
                if(result.status === 200) {
                    let resultContent = JSON.parse(result.content);
                    let tempAchivements = {};
                    setData(data => resultContent);

                    try {
                        if(resultContent.description !== null)
                            tempAchivements = JSON.parse(resultContent.description);
                    } catch (e) {
                        setAchievements(achievements => {});
                    }

                    setAchievements(achievements => tempAchivements)
                }

                setLoading(loading => false);
            })
            .catch(e => {});
    }, [language]);

    /*
        * Veri içerisinde tab değer var ise ilk tab seçili değer olarak set ediliyor.
        * Veriden gönderilen tablar dinamik olarak array içerisine atılıyor.
    */
    useEffect(() => {
        if(Object.keys(data).length > 0) {
            let tempSelectedTab = "";

            data.books.map((item, index) => {
                if(item.categoryTitle && index === 0){
                    tempSelectedTab = item.categoryTitle;
                }
            })

            setSelectedTab(selectedTab => tempSelectedTab);
        }
    }, [data]);

    /*
        * Eğer seçili tab var ise ilgili veri visibleData olarak set ediliyor.
        * Level içermeyen kitaplarda seçili tab olmadığı için visibleData direkt data olarak set edildi.
    */
    useEffect(() => {
        if(selectedTab){
            let tempVisibleData = data.books.find(item => item.categoryTitle === selectedTab);
            setVisibleData(visibleData => tempVisibleData);
        } else {
            setVisibleData(visibleData => data);
        }
    }, [selectedTab])

    useEffect(() => {
        if (IS_MOBILE) {
            const page = {
                "second_page": true
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(page));
        }
    }, [])

    useEffect(() => {
        if(Object.keys(data).length > 0)
            !IS_EMBEDDED_URL && analytics.useAnalytics(
                EventLogs.EDUCATION_PROGRAM_MARKET_DETAIL_VIEW,
                { program_name: data.englishTitle }
            )
    }, [data]);

    return (
        <div className={` ${!IS_EMBEDDED_URL ? `${IS_MOBILE ? "main-content-mobile" : "main-content"}` : "mx-5"}`}>
            <div className="left-page">

                {loading && <Loading/>}

                {!loading && (
                    <>
                        <div className="transparent-section mt-5 text-sm text-start">
                            <h5 className="text-secondary">{strings.speaking_lesson.education_packages.title}</h5>

                            {Object.keys(achievements).length > 0 && (
                                <Accordion
                                    title={strings.market.education_packages.program_achievements.replace("#title#", achievements.title)}
                                    isOpen={true}
                                    disableOutsideClick={true}
                                    children={
                                        <>
                                            <div className="flex flex-col gap-3">
                                                {achievements.descriptions.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className="flex gap-1"
                                                    >
                                                        <span className="font-bold">{index + 1})</span>
                                                        <p>{item}</p>
                                                    </div>
                                                ))}
                                                <div className="flex items-center gap-1 mt-2 pb-4">
                                                    <img src={Idea} width={20} height={20} alt="idea"/>
                                                    <p className="font-bold">
                                                        {strings.market.education_packages.word_count.replace("#count#", achievements.wordCount)}{", "}
                                                        {strings.market.education_packages.hint_count.replace("#count#", achievements.hintCount)}
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    }
                                />
                            )}

                            <ActionIcons/>

                            <LevelTab
                                state={selectedTab}
                                setState={setSelectedTab}
                            />

                            {Object.keys(visibleData).length > 0 && (
                                visibleData?.bookContentItems.map((book, index) => {

                                    let bookUnitTitle = book.unitTitle ? book.unitTitle : strings.speaking_lesson.education_packages.units;
                                    let totalUnitCount = book.bookUnitContents.length;

                                    return (
                                        <Accordion
                                            key={index}
                                            title={`${bookUnitTitle} - 0/${totalUnitCount}`}
                                            isOpen={index === 0}
                                            disableOutsideClick={showModal}
                                            children={
                                                <>
                                                    {book.bookUnitContents.map((bookContent, index) => (
                                                        <div
                                                            key={index}
                                                            className={classNames("", {
                                                                "pb-4": book.bookUnitContents.length - 1 === index
                                                            })}
                                                        >
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex gap-2 items-center">
                                                                    <img
                                                                        src={getActionIconByActionStatus(bookContent?.educationProgramStatus).icon}
                                                                        className={classNames("rounded-md border", {
                                                                            [getActionIconByActionStatus(bookContent?.educationProgramStatus).className]: [!!getActionIconByActionStatus(bookContent?.educationProgramStatus).className]
                                                                        })}
                                                                        width={26}
                                                                        height={26}
                                                                        alt="content-icon"
                                                                    />
                                                                    <p className="text-start">{index + 1}) {bookContent.bookTitle}</p>
                                                                </div>
                                                                {bookContent.bookUrl.length !== 0 && (
                                                                    <button
                                                                        type="button"
                                                                        className="material-symbols-outlined text-base-text cursor-pointer"
                                                                        onClick={() => openModal(bookContent, book.bookId)}
                                                                    >
                                                                        article
                                                                    </button>
                                                                )}
                                                            </div>
                                                            {book.bookUnitContents.length - 1 !== index &&
                                                                <hr className="border border-[#f0f0f0]"/>}
                                                        </div>
                                                    ))}
                                                </>
                                            }
                                        />
                                    )
                                })
                            )}
                        </div>

                        <div className="btn-outer-div">
                            <button
                                type="button"
                                className="button primary-button my-7"
                                onClick={handleNavigateButton}
                            >
                                {strings.speaking_lesson.education_packages.start_lessons}
                            </button>
                        </div>
                    </>
                )}

                <BookModal
                    ref={modalRef}
                    src={selectedBookUrl}
                    toggleModal={closeModal}
                    timeoutState={showModal}
                />

                <div className="left-margin-bottom"></div>
            </div>
        </div>
    )
}

export default Detail;