import React, {useEffect, useRef, useState} from 'react'
import {useLocalization} from '../../hooks/useLocalization';
import {
    Button_Right_Arrow,
    Check,
    Onboarding_1_1,
    Onboarding_1_2,
    Onboarding_1_3,
    Onboarding_1_4
} from '../../assets/svg';
import {Button, Loading, ModalOneButton, ModalOverlay} from '../../components';
import {closeModal, openModal} from '../../services/Auth/AuthValidation';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {url} from '../../routes/utility';
import useAnalytics from '../../hooks/useAnalytics';
import useAuth from '../../hooks/useAuth';
import useMemberSurvey from "../../hooks/useMemberSurvey";
import {AIKeys, LevelDefinitionType, MemberStatus} from "../../components/Constants";
import useAiRouting from "../../hooks/useAiRouting";
import {getQueryParam} from '../../utils/Static';
import {AddMemberLevelCollection} from "../../services/Auth/AuthService";

const OnboardingSecond = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const location = useLocation();
    const navigate = useNavigate();
    const { member } = useAuth();
    const { survey, surveyLoading } = useMemberSurvey();
    const useCaseId = useAiRouting(AIKeys.LEVEL_FINDER);

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [answer, setAnswer] = useState();
    
    // familyCode varsa, değerini döndürüyoruz, yoksa null döndürüyoruz
    const familyCode = getQueryParam('familyCode', location);

    const handleUpdateLevel = () => {
        if(answer) {
            AddMemberLevelCollection(member?.MemberId, LevelDefinitionType.SELF, answer)
                .then(result => {
                    if(result.status === 200) {
                        analytics.useAnalytics('Onboarding_1');
                        if (familyCode) navigate(url("mobile.joinfamily") + window.location.search)
                        else navigate(url("success"));
                    } else {
                        analytics.useAnalytics('Error_Occured', {
                            error: `An unexpected error occurred.`,
                        });
                        openModal(modalOverlayRef, modalRef);
                    }
                })
                .catch()
        } else {
            if (familyCode) navigate(url("mobile.joinfamily") + window.location.search)
            else navigate(url("success"))
        }
    }

    // Seviye Testi AI Butonuna tıklandığında yönlendirmeyi sağlayan fonksiyon.
    const handleNavigateLevelAi = () => {
        navigate(url("speakinglesson.chatbot", { useCaseId: useCaseId }));
    }

    //Kullanıcının daha önce seçtiği cevap var ise set ediliyor.
    useEffect(() => {
        if(!surveyLoading){
            setAnswer(answer => survey.second);
        }
    }, [surveyLoading]);

    useEffect(() => {
        //Eğer giriş yapan kullanıcı öğrenci ise second onboarding ekranına girerse direkt
        // olarak dashboard'a yönlendirmek için kullanılan kod
        if (+member?.Status === MemberStatus.Active) {
            navigate(url("dashboard"));
        }
    }, []);

    return (
        <>
            {
                surveyLoading && <div className='w-full mx-auto mt-5'>
                    <Loading />
                </div>
            }
            {
                !surveyLoading && (
                    <div className="right-onboarding flex flex-col justify-center items-center">
                        <>
                            <div className="text-center mx-auto">
                                <p className="text-xl">
                                    <span className="font-bold">2/2 </span> {strings.onboarding.first.title}</p>
                            </div>
                            <div className="onboarding-outer-div">
                                <button type='button'
                                        className={`onboarding-button ${answer === 2 ? "bg-onboarding-bg-select" : ''}`}
                                        onClick={() => setAnswer(2)}>
                                    <div className={`onboarding-check ${answer === 2 ? "flex" : "hidden"}`}>
                                        <img src={Check} alt="" width="16" height="16"/>
                                    </div>
                                    <div className="onboarding-button-left">
                                        <img src={Onboarding_1_1} alt="" width="32" height="32"/>
                                    </div>
                                    <p className="text-start">{strings.onboarding.first.level1}</p>
                                </button>

                                <button type='button'
                                        className={`onboarding-button ${answer === 3 ? "bg-onboarding-bg-select" : ''}`}
                                        onClick={() => setAnswer(3)}>
                                    <div className={`onboarding-check ${answer === 3 ? "flex" : "hidden"}`}>
                                        <img src={Check} alt="" width="16" height="16"/>
                                    </div>
                                    <div className="onboarding-button-left">
                                        <img src={Onboarding_1_2} alt="" width="32" height="32"/>
                                    </div>
                                    <p className="text-start">{strings.onboarding.first.level2}</p>
                                </button>

                                <button type='button'
                                        className={`onboarding-button ${answer === 4 ? "bg-onboarding-bg-select" : ''}`}
                                        onClick={() => setAnswer(4)}>
                                    <div className={`onboarding-check ${answer === 4 ? "flex" : "hidden"}`}>
                                        <img src={Check} alt="" width="16" height="16"/>
                                    </div>
                                    <div className="onboarding-button-left">
                                        <img src={Onboarding_1_3} alt="" width="32" height="32"/>
                                    </div>
                                    <p className="text-start">{strings.onboarding.first.level3}</p>
                                </button>

                                <button type='button'
                                        className={`onboarding-button ${answer === 5 ? "bg-onboarding-bg-select" : ''}`}
                                        onClick={() => setAnswer(5)}>
                                    <div className={`onboarding-check ${answer === 5 ? "flex" : "hidden"}`}>
                                        <img src={Check} alt="" width="16" height="16"/>
                                    </div>
                                    <div className="onboarding-button-left">
                                        <img src={Onboarding_1_4} alt="" width="32" height="32"/>
                                    </div>
                                    <p className="text-start">{strings.onboarding.first.level4}</p>
                                </button>
                            </div>
                            <div className="btn-outer-div mb-3">
                                <Button
                                    type="button"
                                    classnames="button primary-button"
                                    text={strings.onboarding.next_button}
                                    action={handleUpdateLevel}
                                />
                            </div>
                            <div className="btn-outer-div mb-3">
                                <Button
                                    type="button"
                                    classnames="button primary-button-outline text-[16px]"
                                    text={strings.ai_chatbot.level_test_ai}
                                    action={handleNavigateLevelAi}
                                />
                            </div>

                            <div className="absolute top-[10px] right-[10px]">
                                <Link to={url('onboarding.first') + window.location.search} className="navbar-btn border-[#C1C1C1] md:hidden">
                                    <img className="w-[10px] h-[18px]" src={Button_Right_Arrow} alt="back-button"/>
                                </Link>
                            </div>
                        </>
                    </div>
                )
            }

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef}/>
            <ModalOneButton
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.onboarding.information_messages.make_a_choice}
                overlayRef={modalOverlayRef}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => closeModal(modalOverlayRef, modalRef)}
            />
        </>
    )
}

export default OnboardingSecond