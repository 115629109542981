import {default as React, useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {staticData} from '../../../assets/data/staticData';
import {Ana_Sayfa_Ko_Mobile, Logo_Square} from '../../../assets/img';
import {Default_Teacher, Target} from '../../../assets/svg';
import {Button, ModalOverlay, ModalYoutube, TermDropDown} from '../../../components';
import {DemoStatusType, EventLogs} from '../../../components/Constants';
import {
    addTimeZone,
    checkMicrophoneAllowed,
    checkNotificationAllowed,
    formatNoneZoreDateToZeroDateForDot,
    getBrowserName,
    getTimeDiff,
    getTimeZone,
    getyyyyMMddDate2,
    openModal,
    closeModal,
    replaceTextWithButton
} from '../../../utils';
import useAnalytics from '../../../hooks/useAnalytics';
import useAuth from '../../../hooks/useAuth';
import {useLocalization} from '../../../hooks/useLocalization';
import {url} from '../../../routes/utility';
import {GetDashboardLessonData} from '../../../services/Dashboard/DashboardService';
import StudentComment from './StudentComment';
import DashboardOnboardingExercise from "./DashboardOnboardingExercise";
import {FirebaseContext} from '../../../store/FirebaseProvider';
import {CheckMemberDemoRight} from '../../../services/MemberDemoRight/MemberDemoRightServices';
import classNames from 'classnames';
import useDemoAvailability from "../../../hooks/useDemoAvailability";
import {DemoAutoTeacher} from "../../Demo";

const CandidateAndInactiveStudentCommon = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const { member } = useAuth();
    const memberId = member?.MemberId;
    const demoAvailability = useDemoAvailability(memberId);

    //exerciseType = 0 ise Günün Çalışması görünecek
    //exerciseType = 1 ise Videolarla İngilizce Öğrenmenin Keyfine Var! görünecek
    const exerciseType = useContext(FirebaseContext).exerciseType;

    const language = useSelector(state => state.localizationStore.language);
    const timeZone = getTimeZone(member.Timezone);

    const terms = member.Terms && JSON.parse(member.Terms);
    const selectedTermId = useSelector(state => state.termStore.termId);
    const selectedTerm = terms && terms.find(term => term.TermId === selectedTermId) || false;

    const modalRef = useRef();
    const modalOverlayRef = useRef();
    const modalAutoDemoTeacherSelectionRef = useRef();

    const videoCount = staticData.studentComments.length

    const [lessonInfo, setLessonInfo] = useState({ "text": "", "teacherName": "", "teacherImg": "" });
    const [selectedTermStatus, setSelectedTermStatus] = useState(null);
    const [termId, setTermId] = useState(useSelector(state => state.termStore.termId) || false);
    const [videoUrl, setVideourl] = useState('');
    const [lessonData, setLessonData] = useState(false);
    const [isNotificationAllowed, setIsNotificationAllowed] = useState(null);
    const [isMicrophoneAllowed, setIsMicrophoneAllowed] = useState(null);
    const [isMemberDemoRightExist, setIsMemberDemoRightExist] = useState(true);
    const [browser, setBrowser] = useState(null);
    const [showAutoDemoTeacherSelectionModal, setShowAutoDemoTeacherSelectionModal] = useState(false);

    const isPermissionsGiven = isMicrophoneAllowed === "granted" && isNotificationAllowed === "granted"

    //from child dropdown
    const changeTerm = term => {
        setSelectedTermStatus(selectedTermStatus => term.Status)
        setTermId(term.TermId);
    };

    const checkDotForDate = dt => {
        return dt.includes(".");
    }

    const createDateFromDotDate = dt => {
        let dateArr = dt.split(".");
        let date = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
        return date;
    }

    const createDateFromDotDateToDashDateWithTime = (date, lessonTime) => {
        let [day, month, year] = date.split('.');
        let [hour, minute] = lessonTime.split(':');

        // Eğer "day" değeri 10'dan küçükse başına sıfır ekleniyor
        // Eğer "day" değeri 10'dan küçükse ve tek haneli olarak geliyorsa başına sıfır ekleniyor
        if (parseInt(day) < 10 && day.length === 1) {
            day = `0${day}`;
        }

        // Eğer "month" değeri 10'dan küçükse ve tek haneli olarak geliyorsa başına sıfır ekleniyor
        if (parseInt(month) < 10 && month.length === 1) {
            month = `0${month}`;
        }

        return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
    }

    const showDateWithFormat = dt => {
        let dateArr = dt.split(".");
        let date = ("0" + dateArr[0]).slice(-2) + "." + ("0" + dateArr[1]).slice(-2) + "." + dateArr[2];
        return date;
    }

    const getDashboardLessonData = (termId) => {

        GetDashboardLessonData(termId)
            .then(result => {
                if (result.status === 200) {
                    let resultContent = JSON.parse(result.content);
                    setLessonData(resultContent);
                }
            })
            .catch()
    }

    const handleDemoLessonTimeChangeButton = () => {
        if (lessonData.demoStatus === DemoStatusType.NormalDemo) {
            navigate(url("support.changeteachertime"))
        } else if (lessonData.demoStatus === DemoStatusType.AutoDemo) {
            navigate(url("demo"), { state: {"source": "dashboard"}});
        }
    }

    const checkMemberDemoRight = async (memberId) => {
        const result = await CheckMemberDemoRight(memberId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setIsMemberDemoRightExist(isMemberDemoRightExist => resultContent);
        } else {
            setIsMemberDemoRightExist(isMemberDemoRightExist => false);
        }
    }

    // Mikrofon izni isteyen fonksiyon
    const getMicrophonePermission = () => {
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(function (stream) {
                    setIsMicrophoneAllowed(isMicrophoneAllowed => true);
                    analytics.useAnalytics(EventLogs.MICROPHONE_PERMISSION_APPROVED);
                })
                .catch((e) => {
                    //Eğer mikrofon izni daha önce reddedilmiş ya da verilip kaldırılmışsa hataya düşüyor
                    //böyle bir durum yaşanırsa kullanıcıyı bilgilendirmek için modal açıyoruz
                    openModal(modalOverlayRef, modalRef);
                })
        }

    }

    // Bildirim izni isteyen fonksiyon
    const getNotificationRequest = async () => {
        if (!("Notification" in window))
            alert("This browser does not support Desktop notifications");

        if (Notification.permission !== "granted") {
            await Notification.requestPermission((permission) => {
                if (permission === "granted") {
                    setIsNotificationAllowed(isNotificationAllowed => true);
                    analytics.useAnalytics(EventLogs.NOTIFICATION_PERMISSION_APPROVED);
                    callNotify("Success", "Allowed notification!", Logo_Square, '/sound/success.mp3');
                    //Eğer chrome ya da edge tarayıcı ise mikrofon iznini de almadan sayfayı yenilemiyoruz
                    if (browser === "chrome" || browser === "edge") {
                        checkMicrophoneAllowed().then(status => {
                            if (status === "granted") {
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                            }
                        });
                    } else {
                        //Eğer yukarıdaki 2 tarayıcıdan birisi değilse direkt sayfayı yeniliyoruz
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                    }

                }
            });
        }
    }

    // Kullanıcıdan bildirim ve mikrofon izni vermesini istediğimizde bu fonksiyon çağırılıyor
    const callNotify = (title, msg, icon, sound) => {
        new Notification(title, { body: msg, icon: icon });
        new Audio(sound).play();
    }

    useEffect(() => {
        memberId && checkMemberDemoRight(memberId)
    }, [memberId])

    //when termId change get term info
    useEffect(() => {
        let abortController = new AbortController();

        if (termId) {
            getDashboardLessonData(termId);
        }

        return () => {
            abortController.abort();
        };
    }, [termId])

    //update lesson dates
    useEffect(() => {

        //if there is data for lesson
        if (lessonData) {

            //if lesson data has a date
            if (lessonData.lessonDate !== undefined) {

                let lessonDate = formatNoneZoreDateToZeroDateForDot(lessonData.lessonDate);
                if (checkDotForDate(lessonData.lessonDate)) {
                    lessonDate = createDateFromDotDate(lessonDate);
                }

                //get time difference for next lesson
                const counterLesson = getTimeDiff(new Date(`${lessonDate} ${addTimeZone(lessonData.lessonTime, timeZone)}`), new Date());

                //create an object for lesson info content
                let info = { "text": "", "teacherName": "", "teacherImg": "" };
                let lessonMessage = "";

                if (createDateFromDotDateToDashDateWithTime(lessonData.lessonDate, addTimeZone(lessonData.lessonTime, timeZone)) >= new Date()) {
                    //change values for current language
                    lessonMessage = strings.dashboard.demo_student.lesson_info;
                    lessonMessage = lessonMessage.replace(/#counter#/g, counterLesson);
                    lessonMessage = lessonMessage
                        .replace(/#name#/g, selectedTerm?.StudentName || member.FullName || "")
                        .replace(/#date#/g, showDateWithFormat(lessonData.lessonDate))
                        .replace(/#time#/g, addTimeZone(lessonData.lessonTime, timeZone))
                        .replace(/#day#/g, strings.dashboard.student.day)
                        .replace(/#hour#/g, strings.dashboard.student.hour)
                        .replace(/#minute#/g, strings.dashboard.student.minute);

                } else {
                    lessonMessage = strings.dashboard.student.in_lesson_time_message;
                    lessonMessage = lessonMessage.replace(/#name#/g, member.FullName ?? "");
                }

                //update info object
                info.text = lessonMessage;
                info.teacherName = lessonData.teacherModel?.fullName.replace(" (Sanal Egitmen)", "");
                info.teacherImg = lessonData.teacherModel?.profilePicture;
                //set lesson info for render
                setLessonInfo(info);
            }
        }
    }, [lessonData, language])

    useEffect(() => {
        //Tarayıcı ismi alınıyor ve ilgili değişkene atanıyor
        let browserName = getBrowserName();
        setBrowser(browser => browserName);
        checkMicrophoneAllowed().then(status => (
            setIsMicrophoneAllowed(status)
        ));
        checkNotificationAllowed().then(status => (
            setIsNotificationAllowed(status)
        ));
    }, [])

    return (
        <>
            {((lessonData.demoStatus === DemoStatusType.NormalDemo || lessonData.demoStatus === DemoStatusType.AutoDemo) && terms) && (
                <>

                    {!lessonData.isAttendedLesson && (
                        <div className="my-5 gray-section">
                            <h5 className="text-primary mb-2">{strings.dashboard.demo_student.demo_lesson_reserved}</h5>
                            { terms?.length > 1 && <TermDropDown terms={terms} changeTerm={changeTerm} /> }

                            <div className="text-sm mt-4 pb-2 max-w-lg mx-auto">
                                {selectedTermStatus !== 0 ? (
                                    <>
                                        <div className="flex flex-col sm:flex-row mb-3">
                                            <div className="flex flex-col items-center sm:block sm:w-[30%] mb-3 sm:mb-0">
                                                <div className='sm:hidden mb-3' dangerouslySetInnerHTML={{ __html: lessonInfo.text }} />
                                                {lessonInfo.teacherName ? (
                                                    <>
                                                        <img
                                                            className={classNames("rounded-full w-[100px] mx-auto", {
                                                                "cursor-pointer": !(lessonData?.teacherModel?.id === 1320 || lessonData?.teacherModel?.id === 1500 || lessonData?.teacherModel?.id === 1501)
                                                            })}
                                                            onClick={() => !(lessonData?.teacherModel?.id === 1320 || lessonData?.teacherModel?.id === 1500 || lessonData?.teacherModel?.id === 1501) && navigate(url("teachers.detail", { teacherId: lessonData?.teacherModel?.id }))}
                                                            src={lessonInfo.teacherImg === null ? Default_Teacher : lessonInfo.teacherImg}
                                                            alt=""
                                                        />
                                                        <p className="font-bold">{lessonInfo.teacherName}</p>
                                                        <p>{formatNoneZoreDateToZeroDateForDot(lessonData.lessonDate)} - {addTimeZone(lessonData.lessonTime, timeZone)}</p>
                                                        <p className="text-[13px] leading-4 text-base-text-light">{replaceTextWithButton(strings.dashboard.demo_student.change_demo_lesson_time, handleDemoLessonTimeChangeButton)}</p>
                                                    </>
                                                ) : (
                                                    <>{strings.dashboard.student.no_teacher_info}</>
                                                )}
                                            </div>
                                            <div
                                                className="sm:w-[70%]"
                                            >
                                                <div className='max-sm:hidden' dangerouslySetInnerHTML={{ __html: lessonInfo.text }} />
                                                {/* {lessonInfo.text} */}
                                                <div className="flex flex-col items-center mt-3 sm:mt-4">
                                                    <Link
                                                        to={url("speakinglesson.dailylesson")}
                                                        className="button w-[95%] xs:w-[330px] primary-button"
                                                        onClick={() => {
                                                            analytics.useAnalytics(
                                                                "Dashboard_Ders_Kitabini_Incele_Click"
                                                            );
                                                            localStorage.setItem("isLessonBookClicked", true);
                                                            lessonData.lessonDate && localStorage.setItem("selectedDate", getyyyyMMddDate2(lessonData.lessonDate));
                                                        }}
                                                    >
                                                        {strings.dashboard.student.view_the_lesson_book}
                                                    </Link>
                                                    <a
                                                        className="button w-[95%] xs:w-[330px] secondary-button mt-3"
                                                        href={process.env.REACT_APP_DOWNLOAD_APP_URL}
                                                        target="_blank" rel="noreferrer"
                                                    >
                                                        {strings.dashboard.demo_student.download_mobil_app}
                                                    </a>
                                                    { !isPermissionsGiven && 
                                                        <button className='button w-[95%] xs:w-[330px] secondary-button mt-3'
                                                            onClick={() => {
                                                                isNotificationAllowed !== "granted" && getNotificationRequest();
                                                                isNotificationAllowed === "granted" && isMicrophoneAllowed !== "granted" && getMicrophonePermission();
                                                            }}>
                                                            {isNotificationAllowed  !== "granted" && strings.dashboard.demo_student.give_notification_permission }
                                                            {isNotificationAllowed === "granted" &&  isMicrophoneAllowed  !== "granted" && strings.dashboard.demo_student.give_microphone_permission }
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        { !isPermissionsGiven && 
                                            <p className="text-[13px] leading-4 text-base-text-light"><span className="font-bold">{strings.ai_chatbot.note}: </span> {strings.dashboard.demo_student.microphone_permission}</p>
                                        }
                                    </>
                                ) : (
                                    <>
                                        <p>{strings.dashboard.student.dashboard_passive_lesson_error}</p>
                                        <Link
                                            to={url("speakinglesson.dailylesson")}
                                            className="button w-[95%] xs:w-[330px] primary-button mb-2 mt-3 mx-auto"
                                            onClick={() =>
                                                analytics.useAnalytics(
                                                    "Dashboard_Ders_Kitabini_Incele_Click"
                                                )
                                            }
                                        >
                                            {strings.dashboard.student.click_for_previous_lessons}
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    
                    {lessonData.isAttendedLesson && (
                        <div className="gray-section mt-5">
                            <h5 className="text-primary">{strings.dashboard.demo_student.demo_lesson_completed}</h5>
                            <p className='mt-[27px]'>{replaceTextWithButton(strings.dashboard.demo_student.lesson_completed_content.replace("#TEACHER#", lessonData.teacherModel.fullName.replace(" (Sanal Egitmen)", "")), () => navigate(url("speakinglesson.dailylesson")))}</p>
                        </div>
                    )}
                </>
            )}

            {/* Loading anında kayma olduğu için sabit height verildi */}
            {exerciseType === 0 && (
                <div className="h-[374px]">
                    <DashboardOnboardingExercise />
                </div>
            )}

            {exerciseType === 1 && (
                <div className="transparent-section mt-4">
                    <h5 className="text-secondary">{strings.dashboard.video_exercise.title}</h5>
                    <p className="mt-[27px]">{strings.dashboard.video_exercise.content}</p>
                    <div className="btn-outer-div my-5">
                        <Button
                            action={() => {
                                analytics.useAnalytics(EventLogs.START_TO_EXPLORE_WITH_VIDEO_BUTTON_CLICKED)
                                navigate(url("videopractice"));
                            }}
                            classnames="button primary-button"
                            text={strings.dashboard.video_exercise.button_text}
                        />
                    </div>
                </div>
            )}
            
            {demoAvailability && isMemberDemoRightExist && (lessonData.demoStatus !== DemoStatusType.NormalDemo && lessonData.demoStatus !== DemoStatusType.AutoDemo) && (
                <div className="gray-section mt-5">
                    <h5 className="text-primary">{strings.dashboard.demo_student.try_demo_title}</h5>
                    <p className="mt-[27px]">{strings.dashboard.demo_student.try_demo_content}</p>
                    <div className="btn-outer-div my-5">
                        <Button
                            action={() => {
                                analytics.useAnalytics(EventLogs.DEMO_PLAN_CLICKED, undefined, false, true);
                                analytics.useAnalytics(EventLogs.DEMO_LESSON_CLICKED, undefined, true, false);
                                setShowAutoDemoTeacherSelectionModal(true);
                                openModal(modalOverlayRef, modalAutoDemoTeacherSelectionRef);
                            }}
                            classnames="button secondary-button"
                            text={strings.dashboard.demo_student.plan_demo_lesson}
                        />
                    </div>
                </div>
            )}

            <div className="gray-section mt-5">
                <h5 className="text-primary">{strings.mentor.title}</h5>
                <div className='flex flex-col xs:flex-row items-center max-w-xl mx-auto gap-3 sm:gap-8 md:mb-10'>
                    <div>
                        <p className="mt-[27px] xs:text-start">{strings.mentor.dashboard_message_1}</p>
                        <p className="mt-[27px] xs:text-start">{strings.mentor.dashboard_message_2}</p>
                    </div>
                    <img className="w-[80px] xs:w-[100px] h-[80px] xs:h-[100px]" src={Target} alt="" />

                </div>
                <div className="btn-outer-div my-5">
                    <Button action={() => {
                        analytics.useAnalytics(EventLogs.ABOUT_MENTOR_CLICK)
                        navigate(url("mentor"))
                    }}
                        classnames="button secondary-button"
                        text={strings.mentor.about_mentor}
                    />
                </div>
            </div>

            <div className="dashboard-header relative mt-16">
                <div className="absolute bottom-0 left-0">
                    <img className="w-[150px] md:w-[176px] rounded-bl-[10px]" src={Ana_Sayfa_Ko_Mobile}
                        alt="" />
                </div>
                <div className="w-[calc(100%-115px)] md:w-[calc(100%-160px)] flex flex-col h-full justify-between pr-3">
                    <p>{strings.dashboard.none_user.advice_1}<br />{strings.dashboard.none_user.advice_2}</p>
                    <Link to={url("dashboard.howtolearn")} className="button leading-4 ml-auto sm:mx-auto text-sm bg-white text-base-text h-[30px] flex items-center justify-center w-28 sm:w-40 hover:text-secondary focus:text-secondary"
                        onClick={() => analytics.useAnalytics('Banner_Click')}>
                        {strings.dashboard.none_user.more_info}
                    </Link>
                </div>
            </div>

            <div className="btn-outer-div my-5">
                <Link to={url("market")} className="button primary-button" onClick={() => analytics.useAnalytics('Egitim_Paketleri_Incele_Click')}>
                    {strings.dashboard.none_user.view_packages}
                </Link>
            </div>

            <div className="bg-box rounded-[10px] p-[10px] mx-auto text-center w-full">
                <h5 className="text-secondary mb-2">{strings.dashboard.none_user.student_comments}</h5>
                <div className={classNames('flex flex-col justify-center mx-auto gap-6 w-full items-center', { 'sm:flex-wrap sm:flex-row': videoCount !== 1 }
                )}>

                    {
                        staticData.studentComments.map((comment, index) => {

                            let imgUrl = "https://img.youtube.com/vi/" + comment.youtubeId + "/0.jpg";

                            return (
                                <StudentComment key={index} imgUrl={imgUrl}
                                    studentName={comment.name}
                                    studentJob={comment.job[language]}
                                    videoClick={() => {
                                        setVideourl("https://www.youtube.com/embed/" + comment.youtubeId);
                                        openModal(modalOverlayRef, modalRef);
                                        analytics.useAnalytics('Testimonial_Video', {
                                            video: comment.name,
                                        });
                                    }}
                                    playVideo={strings.dashboard.none_user.play_video} />
                            )
                        })
                    }

                    {staticData.studentComments.length % 2 !== 0 && (<div className="hidden sm:block sm:w-[48%] max-w-[246px] relative"></div>)}

                </div>
            </div>
            <div className="left-margin-bottom"></div>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalYoutube
                ref={modalRef}
                videoUrl={videoUrl}
                overlayRef={modalOverlayRef}
            />
            {showAutoDemoTeacherSelectionModal &&
                <DemoAutoTeacher
                    ref={modalAutoDemoTeacherSelectionRef}
                    overlayRef={modalOverlayRef}
                    closeAction={() => {
                        setShowAutoDemoTeacherSelectionModal(false);
                        closeModal(modalOverlayRef, modalAutoDemoTeacherSelectionRef);
                    }}
                />
            }
        </>
    )
}

export default CandidateAndInactiveStudentCommon