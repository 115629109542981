import parsePhoneNumberFromString from "libphonenumber-js"

export const calcDiscount = (price, discount) => {
    //return parseFloat(price.replace('.', '').replace(',', '.')) * discount
    return price * discount
}

//Boş gelen verileri ekrana istediğimiz metin ile yazdırmamızı sağlıyor
export const isItSpecified = (value, message) => {
    return value ? value : message;
}

export const formatPrice = (price, symbol) => {

    const formattedOutput = new Intl.NumberFormat(process.env.REACT_APP_LANG, {
        style: 'currency',
        currency: process.env.REACT_APP_CURRENCY,
        minimumFractionDigits: 2,
    });

    return formattedOutput.format(price).replace(symbol, '')
}

export const formatPriceWithoutCurrency = (price) => {

    const formattedOutput = new Intl.NumberFormat(process.env.REACT_APP_LANG, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formattedOutput.format(price);
}

export const formatCreditCardNumber = value => {
    const v = value
        .replace(/\s+/g, "")
        .replace(/[^0-9]/gi, "")
        .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
        parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : value;
}

export const validateEmail = mail => {
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
    return mail.match(mailFormat) ? true : false;
}

export const checkNumber = e => {

    let keypressed = e.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) ||
        (keypressed >= 96 && keypressed <= 105)
        || keypressed === 8
        || keypressed === 27
        || keypressed === 46
        || keypressed === 9
        || (keypressed >= 35 && keypressed <= 40)) {
        return true;
    }

    return false;
}

//Boş karakter kontrolü yapan fonksiyon
export const hasNonWhitespaceCharacter = (text) =>{
    return /\S/.test(text);
}

//Soru tipleri loglarının(egzersiz) dinamik kontrol edilmesi için yardımcı fonksiyon.
export const getLogDescriptionAndParameter = (logObject) => {
    if(logObject) {
        //Log açıklaması
        let logDescription = logObject.logDescription;
        let parameterObject = {};

        logObject?.parameters.length > 0 &&
        logObject.parameters.map((item) => {
            //Default log parameter key
            let parameterName = item.name;
            //Default log parameter value
            let parameterData = item.data;

            parameterObject[parameterName] = parameterData;
        })

        return { logDescription, parameterObject };
    }

    return false;
}

// İki arrayi karşılaştırır ve ilk array içerisinde ikinci array elemanlarından kaç adet bulunduğunu döndürür
export const compareTwoArrays = (arrayOne, arrayTwo) => {
    const commonElements = arrayOne.filter(item1 => arrayTwo.some(item2 => item2.id === item1.id));
    return commonElements.length;
}

// Video pratik sayfası için taglar içerisinden veri bulunur
export const findTagsByIdAndCategory = (ids, data) => {
    let foundTags = [];

    // Veri üzerinde döngü
    data.forEach(function(category, index) {
        // İstenilen kategoriye mi ait olduğunu kontrol et
        // Etiketleri döngü ile kontrol et
        category.tags.forEach(function(tag) {
            // İstenilen ID'ye sahip mi kontrol et
            if (ids.includes(tag.id)) {
                // Bulunan etiketi listeye ekle
                var foundCategory = foundTags.find(entry => entry.category === (index === 0 ? "level" : "interest"));
                if (foundCategory) {
                    foundCategory.selectedCategories += ", " + tag.name;
                } else {
                    foundTags.push({ category: (index === 0 ? "level" : "interest"), selectedCategories: tag.name });
                }
            }
        });
    });

    const level = foundTags.find(l => l.category === 'level')?.selectedCategories || "";
    const interest = foundTags.find(i => i.category === 'interest')?.selectedCategories || "";

    return { level: level, interest: interest};
}

export const sendEventByDefaultParameters = (event, analytics, defaultParameters, parameters, emptyParameters) => {
    if(defaultParameters) {
        const mergedObject = Object.assign({}, defaultParameters, parameters);
        analytics.useAnalytics(event, mergedObject);
    } else {
        const mergedObject = Object.assign({}, parameters, emptyParameters);
        analytics.useAnalytics(event, mergedObject);
    }
}

//Telefon numarasını ülkeye göre maskeleyen fonksiyon.
export const maskPhoneNumber = (phoneNumber) => {
    try {
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
        if (parsedPhoneNumber) {
            return parsedPhoneNumber.formatInternational();
        } else {
            return "Invalid phone number";
        }
    } catch (error) {
        return "Invalid phone number";
    }
}

// Kopyala/yapıştır ile latin harfleri/karakterleri dışında input girilmesi engelleniyor.
export const allowPasteLatinCharacters = (e) => {
    const pastedText = e.clipboardData.getData('text');

    const latinAlphabetRegex = /^[a-zA-Z\s]*$/;

    if (!latinAlphabetRegex.test(pastedText)) {
        e.preventDefault();
        return;
    }
};

//onKeyDown sırasında latin harfleri dışında karakter girilmesi engelleniyor.
export const allowLatinCharacters = (e) => {
    const turkishVowels = /[iöüşğçİ0123456789]/i;

    if (!e.key.match(/[a-zA-Z]/) && e.key !== " " && !turkishVowels.test(e.key)) {
        e.preventDefault();
    }
};

export const replaceTextWithButton = (text, onClick, className = "font-bold") => {
    const parts = text.split("#button#");

    // Parçaları oluştur
    let returnedText = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            return <button key={index} className={className} onClick={onClick}>{part}</button>;
        }
    });

    return returnedText;
};

export const removePunctuation = (str) => {
    return str.replace(/[^\w\s]/g, '').replace(/\s+/g, ' ');
};