import {ModalFullScreenWhite} from "../../../../components";
import {useLocalization} from "../../../../hooks/useLocalization";
import React, {useEffect, useRef} from "react";
import useAnalytics from "../../../../hooks/useAnalytics";
import {EventLogs} from "../../../../components/Constants";

const ShareStreak = ({ setOpenShareModal, streak }) => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const copiedRef = useRef();

    const imageUrl = `${process.env.REACT_APP_IMAGE_API}/streak/images/az/${streak}.png`;
    const downloadImageUrl = `${process.env.REACT_APP_IMAGE_API}/streak/images/download.php?folder=az&photo=${streak}`;

    const shareWithWhatsapp = () => {
        const shareLink = `${process.env.REACT_APP_WHATSAPP_API_URL}?text=${imageUrl}`;
        window.open(shareLink, "_blank");
    }

    const sendMail = () => {
        const body = encodeURIComponent(imageUrl);

        const mailtoLink = `mailto:?&body=${body}`;
        window.location.href = mailtoLink;
    }

    const copyLink = () => {
        handleCopy(imageUrl)
    }

    const downloadImage = async () => {
        analytics.useAnalytics(EventLogs.STREAK_SHARE_DOWNLOAD_BUTTON_CLICKED, undefined, false, true);
        window.location.href = downloadImageUrl;
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);

        copiedRef.current.classList.remove("hidden");

        setTimeout(() => {
            copiedRef.current.classList.add("hidden");
        }, 1500);
    }

    const SOCIAL_LINKS = [
        {
            name: strings.social_button.whatsapp,
            action: shareWithWhatsapp
        },
        {
            name: strings.social_button.mail,
            action: sendMail
        },
        {
            name: strings.social_button.copy_link,
            action: copyLink
        },
        {
            name: strings.social_button.download,
            action: downloadImage
        }
    ]

    useEffect(() => {
        analytics.useAnalytics(EventLogs.STREAK_SHARE_MODAL_VIEW, undefined, false, true);
    }, []);

    return (
        <ModalFullScreenWhite
            handleCloseClick={() => setOpenShareModal(false)}
        >
            <div className="flex items-center justify-center mt-4 pb-5">
                <div className="flex flex-col max-w-[480px] gap-4 px-4 md:px-0">
                    {imageUrl.length > 0 &&
                        <div className="flex items-center justify-center">
                            <img src={imageUrl} className="rounded-[10px]"  alt="share" />
                        </div>
                    }

                    <div className="transparent-section">
                        <p className="text-start">{strings.video_practice.word_card.share_text}</p>
                        <div className="flex flex-col gap-3 my-4">
                            {SOCIAL_LINKS.map((social, index) => (
                                <div key={index} className="relative group">
                                    {social.name === strings.social_button.copy_link && (
                                        <div ref={copiedRef} className="tooltip-top-content hidden -top-[34px] duration-500">{strings.reference.copied}</div>
                                    )}
                                    <button
                                        className="button primary-button max-w-[330px] mx-auto w-full"
                                        onClick={() => social.action()}
                                    >
                                        {social.name}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </ModalFullScreenWhite>
    );
}

export default ShareStreak;