import React, {useEffect, useRef, useState} from 'react'
import {useLocalization} from '../../hooks/useLocalization';
import {Check, Onboarding_2_1, Onboarding_2_3, Onboarding_2_8} from '../../assets/svg';
import {Button, Loading, ModalOneButton, ModalOverlay} from '../../components';
import {closeModal, openModal} from '../../services/Auth/AuthValidation';
import useAuth from '../../hooks/useAuth';
import {UpdateMemberReasonToLearnEnglish} from '../../services/Auth/AuthService';
import useAnalytics from '../../hooks/useAnalytics';
import {useNavigate, useLocation} from 'react-router-dom';
import {url} from '../../routes/utility';
import useMemberSurvey from "../../hooks/useMemberSurvey";
import {
    Onboarding_2_2,
    Onboarding_2_4,
    Onboarding_2_5,
    Onboarding_2_6,
    Onboarding_2_7,
    Onboarding_2_9
} from "../../assets/img";
import classNames from "classnames";
import {MemberStatus} from "../../components/Constants";
import { getQueryParam } from '../../utils/Static';

const OnboardingFirst = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const location = useLocation();

    const {survey, surveyLoading} = useMemberSurvey();
    const navigate = useNavigate();
    const { member } = useAuth();

    // familyCode varsa, değerini döndürüyoruz, yoksa null döndürüyoruz
    const familyCode = getQueryParam('familyCode', location);

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const MAX_SELECTED_REASON = 5;

    const [modalMessage, setModalMessage] = useState("");
    const [selectedReasons, setSelectedReasons] = useState([]);

    const reasons = [
        { id: 1, title: strings.onboarding.second.use_case1, icon: Onboarding_2_9},
        { id: 2, title: strings.onboarding.second.use_case2, icon: Onboarding_2_8},
        { id: 3, title: strings.onboarding.second.use_case3, icon: Onboarding_2_7},
        { id: 4, title: strings.onboarding.second.use_case4, icon: Onboarding_2_6},
        { id: 5, title: strings.onboarding.second.use_case5, icon: Onboarding_2_5},
        { id: 6, title: strings.onboarding.second.use_case6, icon: Onboarding_2_4},
        { id: 7, title: strings.onboarding.second.use_case7, icon: Onboarding_2_3},
        { id: 8, title: strings.onboarding.second.use_case8, icon: Onboarding_2_2},
        { id: 9, title: strings.onboarding.second.use_case9, icon: Onboarding_2_1}
    ]

    /*
        * if -> Tıklanılan reason eğer seçili reason'lar arasında yoksa ve seçili değerler maksimum seçme sınırına ulaştıysa modal açılıyor.
        * else if -> Seçilen değer, seçili reason'lar içerisinde var ise kaldırılıyor.
        * else if -> Yeni reason seçili reason'lar içerisine ekleniyor.
    */
    const handleSelectedReason = (reasonId) => {
        if(!selectedReasons.includes(reasonId) && selectedReasons.length === MAX_SELECTED_REASON) {
            setModalMessage(modalMessage => strings.onboarding.information_messages.max_choice_message);
            openModal(modalOverlayRef, modalRef);
        } else if (selectedReasons.includes(reasonId)) {
            setSelectedReasons(selectedReasons => selectedReasons.filter((id) => reasonId !== id));
        } else {
            setSelectedReasons(selectedReasons => [...selectedReasons, reasonId]);
        }
    }

    const submitHandle = async e => {
        e.preventDefault();

        if (selectedReasons.length > 0) {

            let model = {
                memberId: member?.MemberId,
                memberReasonTypeIds: selectedReasons
            }

            const result = await UpdateMemberReasonToLearnEnglish(model);

            if (result.status === 200) {
                analytics.useAnalytics('Onboarding_2');

                +member?.Status === MemberStatus.Candidate
                    ? navigate(url("onboarding.second") + window.location.search)
                    : familyCode 
                        ? navigate(url("mobile.joinfamily") + window.location.search)
                        : navigate(url("success"))

            } else {
                let resultContent = JSON.parse(result.content);

                if (resultContent) {
                    analytics.useAnalytics('Error_Occured', {
                        error: `${resultContent}`,
                    });
                }
                else {
                    analytics.useAnalytics('Error_Occured', {
                        error: `An unexpected error occurred.`,
                    });
                }

                setModalMessage(modalMessage => strings.general_information_messages.an_error_occured);
                openModal(modalOverlayRef, modalRef);
            }

        } else {
            setModalMessage(modalMessage => strings.onboarding.information_messages.make_a_choice);
            openModal(modalOverlayRef, modalRef);
        }
    }

    useEffect(() => {
        if(survey?.first?.length > 0)
            setSelectedReasons(selectedReasons => survey.first);
    }, [survey]);

    return (
        <>
            <div className="right-onboarding flex flex-col justify-center items-center lg:py-5">
                {
                    surveyLoading && <div className='w-full mx-auto mt-5'>
                        <Loading/>
                    </div>
                }
                {
                    !surveyLoading && (
                        <>

                            <div className="text-center mx-auto">
                                <p className="text-xl">
                                        <span
                                            className="font-bold">{+member.Status === MemberStatus.Candidate ? "1/2" : "1/1"}</span>
                                    {" "}{strings.onboarding.second.title}
                                </p>
                            </div>
                            <form id="form" onSubmit={submitHandle}>
                                <div className="onboarding-outer-div">
                                    {reasons.map((reason, index) => (
                                        <button
                                            key={index}
                                            type='button'
                                            className={classNames("w-full h-[50px] leading-[22px] relative overflow-hidden rounded-[10px] border border-onboarding-border mb-4 lg:mb-5 pl-[70px] pr-7 hover:bg-onboarding-bg-select", {
                                                "bg-onboarding-bg-select md:text-md text-base": selectedReasons.includes(reason.id)
                                            })}
                                            onClick={() => handleSelectedReason(reason.id)}
                                        >
                                            <div
                                                className={`onboarding-check ${selectedReasons.includes(reason.id) ? "flex" : "hidden"}`}>
                                                <img src={Check} alt="check" width="16" height="16"/>
                                            </div>
                                            <div className="onboarding-button-left">
                                                <img src={reason.icon} alt="icon" width="32" height="32"/>
                                            </div>
                                            <p className="text-start">{reason.title}</p>
                                        </button>
                                    ))}
                                </div>
                                <div className="btn-outer-div mb-3">
                                    <Button type="submit" classnames="button primary-button"
                                            text={strings.onboarding.next_button}/>
                                </div>
                            </form>
                        </>
                    )
                }
            </div>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef}/>
            <ModalOneButton
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={modalMessage}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => closeModal(modalOverlayRef, modalRef)}
            />
        </>
    )
}

export default OnboardingFirst