import React, {useEffect, useRef, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {CampaignTitle, CampaignType, MemberStatus} from "../../../../components/Constants";
import {
    calcDiscount,
    closeModal,
    convertToTurkishUpper,
    formatPrice, formatPriceWithoutCurrency,
    getLanguageIdByLanguageKey,
    openModal
} from "../../../../utils";
import {CheckDiscountCode, GetAutoApplyCampaigns, GetMemberCampaigns} from "../../../../services/Market/MarketService";
import {useSelector} from "react-redux";
import {ModalOneButton, ModalOverlay} from "../../../../components";
import classNames from "classnames";

const ApplyDiscount = (props) => {

    const { selectedProduct, setDiscountInformation, memberInfo } = props;

    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);
    const currencySymbol = selectedProduct?.productDetail?.priceDetail?.currencySymbol;

    const modalOverlayRef = useRef();
    const discountModalRef = useRef();
    const discountRef = useRef();

    // İkinci kayıt indirim oranının tutulacağı state.
    const [secondPurchaseDiscountRate, setSecondPurchaseDiscountRate] = useState(0);
    // İkinci kayıt indirim tutarının tutulacağı state.
    const [secondPurchaseDiscountPrice, setSecondPurchaseDiscountPrice] = useState(0);
    // Servisten getirilen member'a ait kampanya listesinin tutulduğu state.
    const [campaignList, setCampaignList] = useState([]);
    // Kampanya hata mesajlarının tutulduğu state.
    const [discountModalMessage, setDiscountModalMessage] = useState("");
    // Kullanıcının son ödeyeceği fiyatın tutulduğu state.
    const [totalPrice, setTotalPrice] = useState(0);
    // Kullanıcının uyguladığı toplam indirim tutarını tutan state.
    const [totalDiscountPrice, setTotalDiscountPrice] = useState(0);
    // Geçici olarak indirimlerin atıldığı state.
    const [tmpDiscounts, setTmpDiscounts] = useState([]);
    // İndirimlerin ayıklanarak atıldığı state.
    const [discounts, setDiscounts] = useState([]);
    // Toplam indirim oranının tutulduğu state.
    const [totalDiscount, setTotalDiscount] = useState(0);
    // Kullanıcının satın alacağı ders sayısının tutulduğu state
    const [totalLessonCount, setTotalLessonCount] = useState(0);
    // Kullanıcının hediye ders sayısının tutulduğu state
    const [giftLessonCount, setGiftLessonCount] = useState(0);
    // Ders başı indirimli ücretin tutulduğu state
    const [discountPerLessonPrice, setDiscountPerLessonPrice] = useState("");

    // Kampanya başlıklarını getiren fonksiyon
    const getDiscountLabel = (discountType, discount, campaignTitle) => {

        if(campaignTitle.length === 0)
            switch (discountType) {
                case CampaignType.MoneyBox:
                    return strings.market.checkout.moneybox_discount;
                case CampaignType.Reference:
                    return strings.market.checkout.reference_discount;
                case CampaignType.Discount:
                    return strings.market.checkout.fourty_eight_discount.replace("48", discount.campaignCode.slice(0, 2));
                case CampaignType.Campaign:
                    return strings.market.checkout.campaign_discount;
                case CampaignType.Family:
                    return strings.market.checkout.family_package_discount;
                default:
                    return "";
            }
        else
            return campaignTitle
    };

    // İndirim tutarı hesaplamak için kullanılan fonksiyon
    const calculateDiscountedPrice = (price, rate) => {
        return formatPrice(
            -1 *
            Math.abs(calcDiscount(price,(rate) / 100)),
            selectedProduct?.productDetail?.priceDetail?.currencySymbol
        )
    }

    //'Enter' tuşunu tetikleyen fonksiyon.
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            applyDiscount(discountRef?.current.value, true);
        }
    };

    // İndirimler uygulanırken doğruluğunu kontrol eden ve hesaplayan fonksiyon.
    const applyDiscount = async (discountCode, clearInput = false) => {
        discountCode = discountCode.replace(/\s/g, '');
        const appliedDiscount = tmpDiscounts.find(discount => discount.code === convertToTurkishUpper(discountCode));

        if (!appliedDiscount) {

            let result = await CheckDiscountCode(
                discountCode,
                memberInfo?.MemberId,
                memberInfo?.Status,
                selectedProduct?.productId,
                getLanguageIdByLanguageKey(language)
            );

            if (result.statusCode === 200) {
                //Eğer discount listesinde aynı tipte bir indirim varsa buradan true dönüyor.
                let isDuplicateSameCampaignType = tmpDiscounts.find(discount => discount.campaignType !== 2 && discount.campaignType === JSON.parse(result.message).campaignType);

                //Aynı tipte indirim var ise indirim listesine atmıyoruz ve kullanıcıya hata veriyoruz.
                if (!isDuplicateSameCampaignType) {
                    if (totalDiscount < 100) {
                        setTmpDiscounts(tmpDiscounts => [...tmpDiscounts, JSON.parse(result.message)]);
                    }
                    else {
                        setDiscountModalMessage(strings.market.checkout.discount_limit_exceeded);
                        openModal(modalOverlayRef, discountModalRef);
                    }
                } else {
                    setDiscountModalMessage(strings.market.speakingLesson.information_messages.same_type_code_error);
                    openModal(modalOverlayRef, discountModalRef);
                }
            } else {
                setDiscountModalMessage(strings.market.checkout.discount_unsuccess_message);
                openModal(modalOverlayRef, discountModalRef);
            }

            if (clearInput) {
                discountRef.current.value = "";
            }
        } else {
            setDiscountModalMessage(strings.market.speakingLesson.information_messages.same_discount_code_error);
            openModal(modalOverlayRef, discountModalRef);
        }
    }

    // İndirimlerin temizlendiği fonksiyon.
    const clearAppliedDiscounts = (isModalOpen = true) => {
        if (isModalOpen) {
            // İndirimler temizlendi mesajını göster ve modal'ı aç.
            setDiscountModalMessage(strings.market.checkout.discounts_cleared);
            openModal(modalOverlayRef, discountModalRef);
        }

        // Otomatik uygulanan indirimler hariç tüm indirim listelerini boşalt.
        setDiscounts(discounts => discounts.filter(item => item?.isAutoApplyDiscount));
        setTmpDiscounts(tmpDiscounts => tmpDiscounts.filter(item => item?.isAutoApplyDiscount));

        const autoAppliedTotalDiscounts = discounts
            .filter(discount => discount?.isAutoApplyDiscount)
            .reduce((sum, discount) => sum + discount?.rate, 0);

        // Otomatik uygulanan indirimler hariç toplam indirimi sıfırla.
        setTotalDiscount(totalDiscount => autoAppliedTotalDiscounts);
    };

    // İndirimlerin tek tek silinebildiği fonksiyon
    const removeDiscount = (discountToRemove) => {
        // Toplam indirimi geçici bir değişkene kopyalıyoruz.
        let tmpTotalDiscount = totalDiscount;

        // Güncellenmiş indirimleri tutacak bir dizi oluşturuyoruz.
        let updatedDiscounts = [];

        // Eğer toplam indirim tamamen kullanılmışsa,
        if (tmpTotalDiscount === 100) {
            // Kaldırmak istenen indirim hariç tüm indirimleri içeren bir dizi oluşturuyoruz.
            updatedDiscounts = tmpDiscounts.filter((discount) => discount.code !== discountToRemove.code);
        } else {
            // Aksi takdirde, mevcut indirimler dizisinden kaldırmak istediğimiz indirimi çıkartıyoruz.
            updatedDiscounts = discounts.filter((discount) => discount.code !== discountToRemove.code);
        }

        // Toplam indirimi, güncellenmiş indirimlerin toplam oranıyla güncelliyoruz.
        setTotalDiscount(totalDiscount => updatedDiscounts.reduce((acc, discount) => acc + discount.rate, 0));

        // State'leri güncelliyoruz.
        setDiscounts(updatedDiscounts);
        setTmpDiscounts(updatedDiscounts);
    };

    // İndirim başlıklarının localization işlemlerini kontrol eden fonksiyon
    const renderDiscountTitle = (discount) => {
        // Eğer indirim başlığı "Saat" içeriyorsa:
        if (!discount.title.includes("Saat")) {
            // "Saat" içermiyorsa sadece indirim başlığını göster.
            return <p className={classNames("", {"font-bold": discount?.isAutoApplyDiscount})}>{discount.title}</p>;
        }

        // Kampanya tipini bul.
        const campaignType = CampaignTitle.find(campaign => campaign.id === discount.campaignType);

        // Eğer kampanya tipi bulunamazsa null döndür.
        if (!campaignType) {
            return null;
        }

        // Kampanya başlığı içinde "#rate#" ifadesi var mı kontrol et.
        const rateReplacement = campaignType.en.includes("#rate#") ? discount.rate : "";

        // Indirim kodunun ilk iki karakterini al.
        const codePrefix = discount.code.slice(0, 2);

        // Kampanya başlığını dil ve içerik değişimleriyle oluştur.
        const renderedTitle = language === "en"
            ? campaignType.en.replace("#rate#", rateReplacement).replace("48", codePrefix)
            : campaignType.local.replace("#rate#", rateReplacement).replace("48", codePrefix);

        // Oluşturulan başlığı göster.
        return <p>{renderedTitle}</p>;
    };

    // Servisten member'a ait kampanyalar getiriliyor
    useEffect(() => {
        GetMemberCampaigns(memberInfo?.MemberId, getLanguageIdByLanguageKey(language))
            .then(result => {
                if(result.status === 200){
                    setCampaignList(campaigns => JSON.parse(result.content));
                }
            })
            .catch(e => {})
    }, [language])

    // Servisten otomatik uygulanacak indirimler getiriliyor
    useEffect(() => {
        if(selectedProduct?.productId) {
            GetAutoApplyCampaigns(getLanguageIdByLanguageKey(language), selectedProduct?.productId)
                .then(result => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);
                        const totalDiscount = resultContent.reduce((total, discount) => total + discount.rate, 0);

                        let tempResultContent = [];
                        resultContent.map((item) => {
                            tempResultContent.push({
                                ...item,
                                isAutoApplyDiscount: true
                            })
                        });

                        setDiscounts(tempResultContent);
                        setTmpDiscounts(tempResultContent);
                        setTotalDiscount(totalDiscount);
                    }
                })
        }
    }, [selectedProduct?.productId, language]);

    // Giriş yapan kullanıcı aday değil ise kullanıcıya göstermek için ikinci kayıt indirim oranı hesaplanıyor.
    useEffect(() => {
        if(Object.keys(selectedProduct).length > 0 && +memberInfo.Status !== MemberStatus.Candidate) {
            const candidatePrice = selectedProduct.productDetail?.candidatePriceDetail?.totalPriceWithoutFormat;
            const studentPrice = selectedProduct.productDetail?.priceDetail?.totalPriceWithoutFormat;

            const tempSecondPurchaseRate = (candidatePrice - studentPrice) * 100 / candidatePrice;
            const tempSecondPurchasePrice = calculateDiscountedPrice(candidatePrice, tempSecondPurchaseRate);

            setSecondPurchaseDiscountRate(secondPurchaseDiscountRate => tempSecondPurchaseRate);
            setSecondPurchaseDiscountPrice(secondPurchaseDiscountPrice => tempSecondPurchasePrice);
        }
    }, [selectedProduct]);

    // Toplam İndirimi oranını hesaplayan kodlar
    useEffect(() => {
        // Toplam indirimi hesaplamak için bir fonksiyon oluşturur.
        const calculateTotalDiscount = () => tmpDiscounts.reduce((total, discount) => total + discount.rate, 0);

        // Kalan indirimi uygulamak için bir fonksiyon oluşturur.
        const applyRemainingDiscount = (updatedDiscounts, remainingDiscount) => {
            for (let i = updatedDiscounts.length - 1; i >= 0 && remainingDiscount > 0; i--) {
                const currentDiscount = updatedDiscounts[i];
                const deduction = Math.min(remainingDiscount, currentDiscount.rate);
                updatedDiscounts[i] = { ...currentDiscount, rate: currentDiscount.rate - deduction };
                remainingDiscount -= deduction;
            }
        };

        // Toplam indirim miktarını hesaplar.
        const totalDiscount = calculateTotalDiscount();

        // Güncellenmiş bir kopya oluşturur.
        const updatedDiscounts = tmpDiscounts.map((discount) => ({ ...discount }));

        // 100'den fazla indirim varsa kalanı uygular.
        let remainingDiscount = totalDiscount - 100;
        applyRemainingDiscount(updatedDiscounts, remainingDiscount);

        setDiscounts((prevDiscounts) => {
            const newDiscounts = updatedDiscounts.filter(
                (newDiscount) => !prevDiscounts.some((prevDiscount) => prevDiscount.code === newDiscount.code)
            );

            return [...prevDiscounts, ...newDiscounts];
        });
        setTotalDiscount(totalDiscount > 100 ? 100 : totalDiscount);
    }, [tmpDiscounts]);

    // Toplam indirim fiyatı hesaplanıyor
    useEffect(() => {

        let tempTotalDiscountPrice = 0;
        let tempTotalPrice = 0;

        const candidatePrice = selectedProduct?.productDetail?.candidatePriceDetail?.totalPriceWithoutFormat;
        const memberPrice = selectedProduct?.productDetail?.priceDetail?.totalPriceWithoutFormat;

        // İkinci kayıt indirimi var ise (Aktif veya Pasif öğrenci)
        if(secondPurchaseDiscountRate > 0) {

            // Hiç indirim uygulanmamış ise aday fiyatından düşülüyor.
            if(discounts.length === 0) {
                tempTotalDiscountPrice = calculateDiscountedPrice(
                    candidatePrice,
                    secondPurchaseDiscountRate);

                tempTotalPrice = calcDiscount(candidatePrice, (100 - secondPurchaseDiscountRate) / 100);
            }

            //indirim uygulanmış ise ikinci kayıt indirimi ve diğer indirimler toplanıyor.
            if(discounts.length > 0) {
                const memberPriceDiscount = calcDiscount(memberPrice, totalDiscount / 100);
                const candidatePriceDiscount = calcDiscount(candidatePrice, secondPurchaseDiscountRate / 100);

                tempTotalDiscountPrice = formatPrice(-1
                    * Math.abs(memberPriceDiscount + candidatePriceDiscount),
                    currencySymbol
                )

                tempTotalPrice = calcDiscount(memberPrice, (100 - totalDiscount) / 100)
            }
        }

        //ikinci kayıt indirimi yok ise (Aday Öğrenci) ve diğer indirimler uygulanmış ise
        if(secondPurchaseDiscountRate === 0 && totalDiscount > 0) {
            tempTotalDiscountPrice = calculateDiscountedPrice(
                memberPrice,
                totalDiscount);

            tempTotalPrice = calcDiscount(memberPrice, (100 - totalDiscount) / 100)
        }

        setTotalDiscountPrice(totalDiscountPrice => tempTotalDiscountPrice);
        setTotalPrice(totalDiscount => tempTotalPrice);
    }, [discounts, secondPurchaseDiscountRate, totalDiscount, selectedProduct]);

    // İndirim bilgileri diğer sayfalara gönderilebilmek için set ediliyor.
    useEffect(() => {
        let tempDiscountInformation = {
            discounts: discounts,
            totalPrice: totalPrice === 0
                ? selectedProduct?.productDetail?.priceDetail?.totalPriceWithoutFormat
                : totalPrice,
            totalDiscount: totalDiscount,
            secondPurchaseDiscountRate: secondPurchaseDiscountRate,
            totalLessonCount: totalLessonCount,
            giftLessonCount: giftLessonCount
        }

        setDiscountInformation(tempDiscountInformation);
    }, [discounts, totalPrice, secondPurchaseDiscountRate, totalDiscount, totalLessonCount, giftLessonCount]);

    // Paketin toplam ve hediye ders sayısının kullanıcıya gösterilmek için ayarlandığı alan
    useEffect(() => {
        if(Object.keys(selectedProduct).length > 0) {
            const totalLessonCount = selectedProduct?.productDetail?.priceDetail?.totalLessonCount;
            const giftLessonCount = selectedProduct?.productDetail?.priceDetail?.giftLessonCount;
            const extraGiftLessonCount = selectedProduct?.productDetail?.priceDetail?.extraGiftLessonCount;
            
            setTotalLessonCount(totalLessonCount);
            setGiftLessonCount(giftLessonCount + extraGiftLessonCount);
        }
    }, [selectedProduct])

    // Paket değiştirildiğinde uygulanan indirim var ise sıfırlar.
    useEffect(() => {
        const allAutoApply = discounts.every(discount => discount?.isAutoApplyDiscount);

        if(totalDiscount > 0 && !allAutoApply) {
            clearAppliedDiscounts();
        }
    }, [selectedProduct.productId]);

    // İndirimli ders başı ücret hesaplanıyor
    useEffect(() => {
        if(selectedProduct) {
            if(totalDiscount > 0) {
                const totalPrice = selectedProduct?.productDetail?.priceDetail?.totalPriceWithoutFormat;
                const totalLessonCount = selectedProduct?.productDetail?.priceDetail?.totalLessonCount;

                const discountTotalPrice = totalPrice * (1 - ((totalDiscount) * 0.01));
                const tempDiscountPerLessonPrice = formatPriceWithoutCurrency(discountTotalPrice / totalLessonCount);

                setDiscountPerLessonPrice(tempDiscountPerLessonPrice);
            }

            if(totalDiscount === 0) {
                setDiscountPerLessonPrice(selectedProduct?.productDetail?.priceDetail?.discountLessonPrice);
            }
        }
    }, [selectedProduct, totalDiscount])

    return (
        Object.keys(selectedProduct).length > 0 && (
            <>
                <div className="btn-outer-div mt-5">
                    <div className="text-[12px] md:text-[14px]">
                        <div className="flex justify-between items-center">
                            <p className="font-bold max-w-[calc(100%-80px)]">
                                {giftLessonCount !== 0 &&
                                    strings.market.speakingLesson.total_lesson_price_with_gift
                                        .replace("#totalLessonCount#", totalLessonCount)
                                        .replace("#totalLessonCountWithoutGift#", totalLessonCount - giftLessonCount)
                                        .replace("#giftLessonCount#", giftLessonCount)
                                }
                                {giftLessonCount === 0 &&
                                    strings.market.speakingLesson.total_lesson_price
                                        .replace("#totalLessonCount#", totalLessonCount)
                                }
                            </p>
                            {/*
                                Aktif ve Pasif Öğrencilerde İkinci kayıt indirimi olduğu için aday fiyatı ekrana basılıyor.
                                Aday öğrenciler için normal KDV dahil fiyat ekrana basılıyor.
                            */}
                            <p className="font-bold text-[12px]">
                                {(+memberInfo?.Status === MemberStatus.Active || +memberInfo?.Status === MemberStatus.Passive) &&
                                    selectedProduct?.productDetail?.candidatePriceDetail?.totalPrice + " "
                                }

                                {+memberInfo?.Status === MemberStatus.Candidate &&
                                    selectedProduct?.productDetail?.priceDetail?.totalPrice + " "
                                }

                                {selectedProduct?.productDetail?.priceDetail?.currencySymbol}
                            </p>
                        </div>

                        {/* İkinci kayıt indirimi var ise tutarı basılıyor */}
                        {secondPurchaseDiscountRate > 0 && (
                            <div className="flex text-[12px] justify-between my-1">
                                <p>{strings.market.checkout.second_purchase_discount}</p>
                                <p>
                                    {secondPurchaseDiscountPrice}{" "}
                                    {selectedProduct?.productDetail?.priceDetail?.currencySymbol}
                                </p>
                            </div>
                        )}

                        {discounts.length > 0 &&
                            discounts.map((discount, index) => (
                                <div
                                    key={index}
                                    className={classNames("flex text-[12px] justify-between my-1 relative", {
                                        "p-1 -mx-2 px-2 bg-[#f1f1f1]  rounded-md border-box": discount?.isAutoApplyDiscount
                                    })}
                                >
                                    <div className='absolute -right-5 top-0.5'>
                                        <button
                                            type='button'
                                            className={classNames("group relative w-4 h-4 rounded-full bg-red hover:bg-opacity-80 focus:bg-opacity-80 text-white flex item-center justify-center", {
                                                "hidden": discount?.isAutoApplyDiscount,
                                                "translate-x-2": !discount?.isAutoApplyDiscount
                                            })}
                                            onClick={() => removeDiscount(discount)}
                                        >
                                            <div
                                                className='tooltip-top -top-[35px]'>{strings.market.checkout.cancel_discount}</div>
                                            <span
                                                className="material-symbols-outlined text-[14px] leading-[16px]">close</span>
                                        </button>
                                    </div>
                                    {renderDiscountTitle(discount)}
                                    <p
                                        className={classNames("", {
                                            "font-bold": discount?.isAutoApplyDiscount
                                        })}
                                    >
                                        {calculateDiscountedPrice(selectedProduct.productDetail.priceDetail.totalPriceWithoutFormat, discount.rate)
                                            + " " + selectedProduct.productDetail.priceDetail.currencySymbol}
                                    </p>
                                </div>
                            ))
                        }

                        {/* Toplam İndirim Tutarının basıldığı alan */}
                        {totalDiscountPrice !== 0 &&
                            <div className="flex justify-between my-1">
                                <p className='font-bold'>{strings.market.speakingLesson.total_discount}</p>
                                <p className='font-bold'>{totalDiscountPrice} {currencySymbol}</p>
                            </div>
                        }

                        <div className="flex justify-between my-1">
                            <p className="font-bold">{strings.market.speakingLesson.price_by_per_lesson}</p>
                            <p className="font-bold">
                                {discountPerLessonPrice}{" "}
                                {selectedProduct?.productDetail?.priceDetail?.currencySymbol}
                            </p>
                        </div>

                        {/* Toplam Fiyat Tutarının basıldığı alan */}
                        {totalPrice !== 0 &&
                            <>
                                <div className="flex justify-between my-1">
                                    <p className='text-secondary font-bold'>{strings.market.checkout.total_amount}</p>
                                    <p className='text-secondary font-bold'>{formatPrice(totalPrice, currencySymbol)} {currencySymbol}</p>
                                </div>
                            </>
                        }

                        {totalDiscount > 0 && <hr></hr>}

                        {/* Member'a ait indirimlerin basıldığı kısım. Uygulunan indirimler listeden çıkarılıyor */}
                        {campaignList.map((campaign, index) => (
                            !discounts.some(d => d?.code === campaign?.campaignCode) && (
                                <div
                                    key={index}
                                    className="flex font-bold text-[12px] justify-between items-center my-2"
                                >
                                    <p>{getDiscountLabel(campaign.campaignType, campaign, campaign.campaignTitle)} (%{campaign.discountRate})</p>
                                    <button
                                        type="button"
                                        className="h-[38px] bg-primary hover:bg-secondary focus:bg-secondary px-2 md:px-3 rounded-[10px] text-white"
                                        onClick={() => applyDiscount(campaign.campaignCode)}
                                    >
                                        {strings.market.speakingLesson.apply}
                                    </button>
                                </div>
                            )
                        ))}

                        {/* Kampanya kodu girilen input */}
                        <div className="my-4 relative">
                            <input
                                ref={discountRef}
                                type="text"
                                className="placeholder:font-normal h-[38px] placeholder:text-[10px] placeholder:md:text-[12px] focus:outline-none focus:border-primary border border-onboarding-border font-normal text-base-text rounded-[10px] p-2 w-full"
                                placeholder={strings.market.speakingLesson.discount_code}
                                onKeyDown={(e) => handleKeyPress(e)}
                            />
                            <button
                                type="button"
                                className="ml-auto mr-0 h-[38px] bg-primary font-bold hover:bg-secondary focus:bg-secondary px-2 md:px-3 rounded-[10px] text-white absolute right-0 z-10"
                                onClick={(e) => applyDiscount(discountRef.current.value, true)}
                            >
                                {strings.market.speakingLesson.apply}
                            </button>
                        </div>
                    </div>

                    {/* İndirimleri kaldır butonu */}
                    {discounts.length > 0 && !discounts.every((item) => item.isAutoApplyDiscount) &&
                        <div className="flex justify-center">
                            <button
                                type='button'
                                className='my-2 text-[12px] cursor-pointer font-bold button text-button'
                                onClick={() => clearAppliedDiscounts(false)}
                            >
                                {strings.market.speakingLesson.remove_discount}
                            </button>
                        </div>
                    }
                </div>

                {/* Modal */}
                <ModalOverlay ref={modalOverlayRef}/>
                <ModalOneButton
                    ref={discountModalRef}
                    overlayRef={modalOverlayRef}
                    title={strings.market.speakingLesson.information_messages.modal_title}
                    message={discountModalMessage}
                    buttonText={strings.market.okay_button}
                    buttonClick={() => closeModal(modalOverlayRef, discountModalRef)}
                />
            </>
        )
    )
}

export default ApplyDiscount;