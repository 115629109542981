import classNames from "classnames";
import React from "react";

const LevelTab = (props) => {

    const { state, setState, data } = props;

    const STATIC_LEVELS = ["Starter", "Beginner", "Intermediate", "Advanced"];

    return (
        <>
            {STATIC_LEVELS.includes(state) && (
                <div className="mx-auto mt-5">
                    <ul id="tabs" className="flex gap-2 sm:gap-5 justify-between mx-auto text-start">
                        {STATIC_LEVELS.map((tab, index) => (
                            <li
                                key={index}
                                className="font-bold w-full relative pb-1 cursor-pointer"
                                onClick={() => setState(selectedTab => tab)}
                            >
                                <button type="button">{tab}</button>
                                <div
                                    className={classNames("absolute bottom-0 left-0 right-0 h-[3px] rounded", {
                                        "bg-primary": state === tab,
                                        "bg-base-text": state !== tab
                                    })}
                                >
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}

export default LevelTab;